/* LOADER PAGE */
.loader__wrapper {
  display: flex;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #03316a;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

/* */
